.overview-header {
  display: flex;
  align-items: center;

  h1 {
    margin-right: 55px;
    margin-bottom: 0;
  }

  .separator {
    border-right: solid 1px #d5dce9;
    width: 1px;
    height: 50px;
    margin-right: 45px;
  }
}
