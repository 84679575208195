.dropdown {

  .dropdown-menu {
    border-radius: 2px;
    border: solid 1px #e9eef8;
  }

  .dropdown-item {
    &:active {
      background-color: #cbd9f5 !important;
    }

    &:hover {
      background-color: #e9eef8 !important;
    }
  }
}
