@import "../../../../../assets/scss/mixins";

.edit-participants-modal {
  .table .table-header {
    border-top: 0;
  }

  .mode-toggle {
    font-size: 14px;
    line-height: 1.21;
    color: #65789d;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }
  }

  @include bootstrap-lg-and-below {
    .tab {
      margin-bottom: 10px;
      width: 100%;
      font-size: 16px;

      &.active {
        border-left: 3px solid;
        padding-left: 10px;

        &:after {
          display: none;
        }
      }
    }
  }
}
