.ck {
  .ck-content {
    border-radius: 2px !important;
    border: solid 1px #e9eef8 !important;
    border-top: 0 !important;

    height: 200px !important;
    max-height: 200px !important;
  }

  .ck-toolbar {
    background: #FAFAFA;
    border-radius: 2px !important;
    border: solid 1px #e9eef8 !important;
    border-bottom: 0 !important;
  }

  .ck-dropdown__panel {
    border-radius: 4px;
    border: solid 1px #e9eef8 !important;
    background-color: #ffffff;
    box-shadow: none !important;
  }

  .ck.ck-list__item .ck-button {
    font-size: 14px;
    line-height: 1.21;
    color: #7c8cac;

    &:hover {
      background-color: #e9eef8;
    }

    &.ck-on {
      background-color: #e9eef8;
      color: #7c8cac;

      &:hover {
        color: #7c8cac;
        background-color: #e9eef8;
      }
    }
  }
}
