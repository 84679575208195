.participants-table {
  .table-row {
    grid-template-columns: 1fr 1.5fr 1fr 120px;
  }
}

.participants-table.waiting-list {
  .table-row {
    grid-template-columns: 1fr 2fr 120px;
  }
}

