.course-group {
  background-color: #CBD9F5;

  h3 {
    color: #7C8CAC;
    font-size: 16px;
    text-transform: uppercase;
  }

  .course-group-component {
    display: grid;
    grid-template-columns: 1fr 150px;

    .course-name {
      font-size: 16px;
      font-weight: bold;
      color: #7C8CAC;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .next-date a, .next-date svg {
      color: #7C8CAC;
    }
  }
}
