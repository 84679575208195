.link-cards {
  .link-card {
    border-radius: 4px;
    box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.1);

    &.green {
      background-color: #d1f5eb;

      h2.card-title {
        color: #64c8a5;
      }

      .card-icon {
        background-color: #64c8a5;
      }
    }

    &.blue {
      background-color: #cbf4fe;

      h2.card-title {
        color: #8dc1ed;
      }

      .card-icon {
        background-color: #8dc1ed;
      }
    }

    &.purple {
      background-color: #cbd9f5;

      h2.card-title {
        color: #7c8cac;
      }

      .card-icon {
        background-color: #7c8cac;
      }
    }

    &.teal {
      background-color: #16879e;
      h2.card-title {
        color: scale-color(#16879e, $lightness: -30%);
      }

      .card-icon {
        background-color: scale-color(#16879e, $lightness: -30%);
      }
    }


    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }

    h2.card-title {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2;
    }

    .card-icon {
      border-radius: 95px;
      height: 150px;
      width: 150px;

      svg {
        font-size: 28px;
        color: white;
      }
    }
  }
}
