.menu {
  $baseLeftPadding: 35px;

  overflow: scroll;
  max-height: 100vh;

  padding-left: 0;

  li {
    list-style-type: none;
    height: 55px;

    display: flex;
    align-items: center;
    padding-left: $baseLeftPadding;

    font-family: "Barlow", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;

    border-bottom: solid 1px rgba(#f3f2f8, 0.2);

    &.menu-link {
      cursor: pointer;
    }

    span {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: 15px;
      width: 16px !important;
    }
  }

  a:hover {
    text-decoration: none;

    li {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  li.menu-link:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }

  a.active li {
    background-color: rgba(255, 255, 255, 0.3);
    border-left: 5px solid white;
    padding-left: $baseLeftPadding - 5px;
  }

  .submenu {
    padding: 0;

    li {
      padding-left: $baseLeftPadding * 2;
      border: 0;
      background-color: rgba(#f8f8f9, 0.15);
    }

    a.active li {
      padding-left: $baseLeftPadding * 2 - 5px;
    }
  }

  h2 {

  }
}
