input[type='checkbox'] {
  position: absolute;

  &:after {
    display: flex;
    justify-content: center;
    align-items: center;

    content: ' ';
    color: white;

    position: relative;
    top: -4px;
    left: 0;

    width: 20px;
    height: 20px;

    border-radius: 2px;
    border: solid 2px #e9eef8;
    background-color: #ffffff;
  }

  &:checked:after {
    width: 20px;
    height: 20px;

    content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 512 512" height="15px">
    <path fill="white"
            d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
    />
</svg>
');
    line-height: 0;
    text-align: center;
    color: white;
    border-radius: 2px;
    border: none;
    background-color: #6dcea6;
  }
}
