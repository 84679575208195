.base-account-screen {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(to bottom, #16879e, #64c8a5);

  .small-link a{
    font-size: 13px;
    font-style: italic;
    line-height: 1.23;
    color: #ffffff;
  }

  hr {
    border-top: 1px solid white;
    width: 35px;
  }

  .paragraph {
    font-size: 15px;
    font-style: italic;
    line-height: 1.2;
    color: #ffffff;

    a {
      font-weight: bold;
      color: white;
    }
  }

  .copyright {
    font-size: 13px;
    line-height: 1.23;
    color: #ffffff;
  }
}
