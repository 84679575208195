.current-budget {
  &.positive-balance {
    border: 3px solid #64c8a5;

    a {
      color: #64c8a5;
    }
  }

  &.negative-balance {
    border: 3px solid #ec4771;

    a {
      color: #ec4771;
    }
  }

  a {
    font-weight: bold;
  }

  padding: 10px;
}
