@import "../../../../../assets/scss/mixins";

.trainer-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  .table-row {
    grid-column-start: 1;
    grid-column-end: 5;
    &.is-loading {
      svg {
        color: #cbd9f5;
        font-size: 50px;
      }
    }
  }

  @include bootstrap-xl-and-below {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include bootstrap-lg-and-below {
    grid-template-columns: 1fr 1fr;
  }

  @include bootstrap-md-and-below {
    grid-template-columns: 1fr;
  }

  &.empty {
    .no-trainers {
      justify-content: center;
    }
  }


  a {
    &:hover {
      text-decoration: none;

      h2, .trainer-type {
        text-decoration: none;
      }
    }
  }
}
