@import "../../../../../../assets/scss/mixins";

.course-table {
  .table-row {
    grid-template-columns: 25px 1fr 1fr 1fr 1fr 1fr 1fr 130px;

    &.cancelled {
      .table-column {
        color: #cbd9f5;
      }
    }

    .name-column {
      .color-bubble {
        display: none;

        @include bootstrap-lg-and-below {
          display: block;
        }
      }
    }
  }
}
