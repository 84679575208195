.trainer-card {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  min-width: 230px;
  border: none;
  margin: 0;
  width: 100%;

  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    color: #363636;
    margin-bottom: 5px;
  }

  .trainer-type {
    font-size: 15px;
    line-height: 1.2;
    color: #363636;
    margin: 0;
  }
}
