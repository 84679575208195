#modal {
  .modal {
    background-color: rgba(#363636, 0.8);

    &.show {
      display: block;
    }

    .modal-header {
      background-color: #e9eef8;

      .close {
        color: #7c8cac;
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}
