.btn {
  border-radius: 25px;
  padding: 14px 40px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  border: none;

  &.btn-primary {
    background-color: #16879e;


    &:hover {
      background-color: #147386;
    }
  }

  &.btn-secondary {
    background-color: #7c8cac;

    &:hover {
      background-color: #6f7d9a;
    }
  }

  &.btn-danger {
    background-color: #ec4771;

    &:hover {
      background-color: #d84268;
    }
  }

  &.btn-success {
    background-color: #64c8a5;

    &:hover {
      background-color: #5cb495;
    }
  }

  &.btn-outline-success {
    border: 2px solid #64c8a5;
    color: #64c8a5;

    &:hover {
      color: white;
      border: 2px solid #64c8a5;
      background-color: #5cb495;
    }
  }
}
