@import "../../../../../../assets/scss/mixins";

.course-overview-header {

  .tab.active:after {
    bottom: -25px;
  }

  @include bootstrap-lg-and-below {

    flex-direction: column;

    h1 {
      margin-bottom: 20px;
    }
    .separator {
      display: none;
    }
    .tab {
      margin-bottom: 10px;
      width: 100%;
      font-size: 16px;

      &.active {
        border-left: 3px solid;
        padding-left: 10px;

        &:after {
          display: none;
        }
      }
    }
  }
}
