.filters {
  display: grid;
  align-items: center;
  grid-gap: 15px;

  .filter-icon {
    font-size: 19px;
    color: #7D8CAC;
  }

  .text-filter {
    position: relative;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      border-radius: 4px;
      border: solid 1px #e9eef8;

      &:focus {
        border: solid 1px #cbd9f5;
        background-color: #ffffff;
      }
    }

    svg {
      position: absolute;
      right: 15px;
      font-size: 17px;
      color: #cbd9f5;
    }
  }

  .react-datetimerange-picker {
    .react-datetimerange-picker__wrapper {
      border-radius: 4px;
      border: solid 1px #e9eef8;
      background-color: #ffffff;
    }

    &.react-datetimerange-picker--open {
      .react-datetimerange-picker__wrapper {
        border: solid 1px #cbd9f5;
        background-color: #ffffff;
      }
    }
  }

  .react-select {
    .react-select__control {
      border-radius: 4px;
      border: solid 1px #e9eef8;

      &.react-select__control--is-focused {
        border: solid 1px #cbd9f5;
        background-color: #ffffff;
        box-shadow: none;
      }
    }
  }

  button.clear-filters {
    display: flex;
    line-height: 1;
    padding: 0;
    justify-content: center;

    svg {
      margin-right: 8px;
    }

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    border: none;
    background: transparent;

    font-size: 15px;
    font-weight: bold;
    text-align: left;
    color: #7c8cac;

    &:hover {
      color: #6f7d9a;
    }
  }
}
