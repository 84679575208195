@import "../../../../assets/scss/mixins";

.filters {
  grid-template-columns: 40px 1fr;
  position: relative;

  .filters-menu-toggle {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: none;
    border: none;
    height: 100%;
    padding: 0;
  }

  .filters-menu {
    display: flex;


    .filter {
      flex-grow: 1;
      flex-basis: 0;
    }

    .filter:not(:last-child) {
      margin-right: 20px;
    }

    @include bootstrap-lg-and-below {
      display: none;

      top: 40px;
      position: absolute;
      flex-direction: column;
      background-color: #e9eef8;
      z-index: 99;
      left: -10px;
      width: 100vw;
      padding: 20px;

      .filter {
        margin-right: 0 !important;
        margin-bottom: 20px;
      }

      button {
        padding: 20px 0;
      }
      &.open {
        display: flex;
      }
    }
  }

  .pagination-info {
    display: none;

    @include bootstrap-lg-and-below {
      display: block;
      color: #7c8cac;
    }
  }
}
