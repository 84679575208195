@import "../../assets/scss/mixins";

.header {

  @include bootstrap-lg-and-below {
    display: none;
  }

  .divider {
    width: 1px;
    height: 100%;
    border-left: solid 1px #cbd9f5;
    margin: 0 20px;
  }

}

.mobile-header {
  padding: 0 !important;
  display: none !important;
  background-color: #16879e;

  @include bootstrap-lg-and-below {
    display: flex !important;
  }

  .always-visible {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 100%;
    height: 100%;

    .mobile-toggle {
      position: absolute;
      left: 20px;

      .mobile-toggle-button {
        color: white;
        font-size: 20px;
        border: none;

        background: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }

      &.toggle-open .mobile-toggle-button {
        color: #5cc1a4;
      }
    }


  }

  .toggleable-area {
    display: none;
    height: 100vh;

    @include bootstrap-lg-and-below {
      max-height: calc(100vh - 70px);
      overflow: scroll;
    }

    &.open {
      display: block;
    }

    .user-menu {
      .name-wrapper {
        .name, .roles {
          color: white;
        }
      }
    }
  }

}
