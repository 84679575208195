@mixin default-input-base {
  padding-left: 15px;

  text-align: left;

  font-size: 15px;
  line-height: 1.2;

  width: 100%;

  border-radius: 2px;
  border: solid 1px #e9eef8;
  background-color: #ffffff;
  color: #363636;

  &:focus {
    border-radius: 4px;
    border: solid 1px #e9eef8;
    background-color: #ffffff;
    box-shadow: none;
  }

  &::placeholder {
    font-size: 14px;
    line-height: 1.2;
    color: #7c8cac;
  }
}

input.default-input {
  @include default-input-base;
  height: 45px;
}

textarea.default-input {
  @include default-input-base;
  padding: 15px;
}
