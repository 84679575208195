.tooltip {
  pointer-events: none;
  .tooltip-inner {
    background-color: #7c8cac !important;
  }

  .arrow:before {
    border-top-color: #7c8cac !important;
  }
}
