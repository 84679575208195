h1, h2, h3, h4, h5, div, span, ul, li, ol, button, input, textarea {
  font-family: 'Barlow', sans-serif;
}


@import "headings";
@import "lead";
@import "badge";
@import "text";
@import "forms";
@import "alerts";
@import "tooltips";
@import "list-group";
@import "modal";
