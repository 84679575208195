input.error {
  border-color: #ec4771;

  &:hover {
    border-color: #ec4771;
  }
}

.validation-error {
  color: #ec4771;
  font-size: 14px;
}
