.form-group {
  label {
    font-size: 16px;
    font-weight: bold;
    color: #7c8cac;
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
  }
}

form {
  width: 100%;
}
