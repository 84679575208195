@import "mixins";
@import "google-fonts/css/barlow.css";
@import "~bootstrap/scss/bootstrap";
@import "bootstrap-overwrites/index";
@import "controls/index";
@import "components/cross";
@import "components/overview-header";
@import '~filepond/dist/filepond.min.css';
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

body {
  height: 100vh;
  overflow: hidden;
}

a {
  cursor: pointer;
}


main {
  overflow: scroll;
  margin: 0 !important;

  padding-top: 130px !important;

  @include bootstrap-lg-and-below {
    padding-top: 70px !important;
  }

  display: flex;
  flex-direction: column;
}

input, textarea, button {
  &:focus {
    outline: none;
  }
}

#root {
  height: 100%;
}

.gradient-bg {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-image: linear-gradient(to bottom, #16879e, #64c8a5);
}

.blue-bg {
  background-color: #e9eef8;
}

header.navbar {
  height: 80px;
}

.bg-light {
  background-color: #f4f7fb;
}


.mobile-bg-light {
  @include bootstrap-lg-and-below {
    background-color: #f4f7fb !important;
  }
}


.navbar {
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: white;
}




