button.tab {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  border: 0;
  background: transparent;
  padding: 0;

  position: relative;
  font-size: 14px;
  font-weight: bold;
  text-align: left;

  margin-right: 80px;

  color: #cbd9f5;

  &:hover {
    color: #16879e;
  }

  &.active {
    color: #16879e;

    &:after {
      position: absolute;
      content: " ";
      width: 100%;
      bottom: -30px;
      left: 0;
      border-bottom: 4px solid #16879e;
    }
  }
}
