.pagination {
  font-size: 14px;
  line-height: 1.21;
  color: #7c8cac;
  flex-wrap: wrap;
  li {
    padding: 10px;
    cursor: pointer;

    svg {
      font-size: 10px;
      line-height: 1;
    }

    &.disabled {
      color: #cbd9f5;
    }
    &.active {
      background-color: #cbd9f5;
      border-radius: 4px;
    }
  }
}
