.trainer-select-field.react-select {
  .react-select__control {
    height: inherit;
  }

  .react-select__value-container {
    height: fit-content !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }
}
