.react-calendar {
  border-radius: 4px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
  border: solid 1px #e9eef8 !important;
  background-color: #ffffff !important;


  .react-calendar__navigation__arrow {
    font-size: 25px;
    color: #7c8cac;
  }

  .react-calendar__navigation__label {
    font-size: 14px;
    line-height: 1.21;
    color: #7c8cac;
    padding-bottom: 0;
  }

  .react-calendar__month-view__weekdays__weekday {
    font-size: 15px;
    font-weight: bold;
    color: #363636;

    abbr {
      text-decoration: none !important;
    }

    &:last-of-type, &:nth-last-of-type(2) {
      color: #7c8cac;
    }
  }

  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #cbd9f5;
  }

  .react-calendar__month-view__days__day--weekend:not(.react-calendar__month-view__days__day--neighboringMonth) {
    color: #7c8cac;
  }

  .react-calendar__tile--active, .react-calendar__tile--rangeStart, .react-calendar__tile--rangeEnd, .react-calendar__tile--rangeBothEnds {
    background-color: #16879e !important;

    &:hover {
      background-color: #147386 !important;
    }
  }

  .react-calendar__tile--rangeStart {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .react-calendar__tile--rangeEnd {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

