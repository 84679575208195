.coaching-offers {
  .table-row {
    display: grid;
    grid-gap: 20px;

    &.is-loading {
      svg {
        color: #cbd9f5;
        font-size: 50px;
      }
    }

    &.no-data {
      font-size: 26px;
      font-weight: bold;
      line-height: 1.19;
      color: #7c8cac;
    }
  }
}
