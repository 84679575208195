.react-select {
  width: 100%;

  .react-select__menu {
    z-index: 2;

    .react-select__option {
      color: #7c8cac;
    }

    .react-select__option--is-selected{
      background-color: #cbd9f5;
    }
    .react-select__option--is-focused {
      background-color: #e9eef8;
    }
  }

  .react-select__control {
    height: 45px;
    border-radius: 2px;
    background-color: #ffffff;
    border: solid 1px #e9eef8;

    &:hover {
      background-color: #ffffff;
      border: solid 1px #e9eef8;
    }

    &.react-select__control--is-focused {
      border: solid 2.5px #e9eef8;
      box-shadow: none;
    }
  }

  .react-select__placeholder {
    font-size: 14px;
    line-height: 1.21;
    text-align: left;
    color: #7c8cac;
  }

  .react-select__value-container--has-value {
    font-size: 15px;
    line-height: 1.21;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    color: #cbd9f5;
    padding-left: 0;
  }

  .react-select__clear-indicator {
    padding-right: 5px;
    color: #cbd9f5 !important;
    font-size: 17px !important;

    &:hover {
      color: #6f7d9a !important;
    }
  }

  .react-select__value-container {
    padding-left: 15px;
    overflow: scroll;
    flex-wrap: nowrap;
  }

  .react-select__multi-value {
    background-color: #e9eef8;
  }

  .react-select__multi-value__remove:hover {
    background-color: #6f7d9a;
    color: white;
  }

}
