@import "../../assets/scss/mixins";

.sidebar {
  @include bootstrap-lg-and-below{
    display: none;

    &.open {
      display: block;
    }
  }
}
