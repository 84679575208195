.badge.badge-pill {
  text-transform: uppercase;
  padding: 5px 20px;

  border-radius: 50px;

  font-family: "Barlow", sans-serif;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  color: #363636;

  &.badge-success {
    background-color: #d1f5eb;
  }

  &.badge-warning {
    background-color: #fefacb;
  }

  &.badge-danger {
    background-color: #feddcb;
  }

  &.badge-info {
    background-color: #cbd9f5;
  }

  &.badge-pending {
    background-color: #cbf4fe;
  }
}
