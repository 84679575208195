.user-menu {


  .dropdown-toggle {
    display: flex;
    align-items: center;
    padding: 0;

    background: none !important;
    border: none !important;
    box-shadow: none !important;

    &:after {
      display: none;
    }

    img {
      height: 40px;
      width: 40px;
      border-radius: 95px;
      margin-right: 20px;
    }

    .name-wrapper {
      font-size: 16px;
      font-weight: bold;
      line-height: 0.88;
      color: #7c8cac;
      text-align: left;

      .roles {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}
