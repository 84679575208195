.waiting-list-switcher {
  button.tab {
    padding: 0 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.19;
    margin-right: 40px;

    &.active {

      &:after {
        bottom: 0px;
      }
    }
  }

  border-bottom: solid 1px #e9eef8;
}
