.course-detail {

  .course-detail-header {
    .detail {

      &:not(:first-child):not(:last-child) {
        justify-content: center;
      }

      &:first-child {
        justify-content: flex-start !important;
      }

      &:not(:last-child) {
        border-right: 1px solid #CBD9F5;
      }

      svg {
        color: #CBD9F5;
      }

      @media screen and (max-width: 1200px) {
          justify-content: flex-start !important;
      }
    }
  }

  h3 {
    color: #7c8cac;
  }

}
