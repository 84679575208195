.breadcrumbs {
  display: flex;
  align-items: center;

  font-size: 13px;
  color: #7c8cac;

  a {
    color: #19899e;
  }

  .separator {
    margin-left: 10px;
    margin-right: 10px;
  }
}
