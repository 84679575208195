.alert {

  &.alert-warning {
    border-radius: 2px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fefacb;
    font-size: 15px;
    line-height: 1.6;
    color: #363636;
  }
}
