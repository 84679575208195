@import "../../../../../../assets/scss/mixins";

.addition-form {
  display: grid;
  grid-gap: 20px;
  align-items: center;
  grid-template-columns: 70px 1fr;

  form {
    display: grid;
    grid-gap: 20px;
    align-items: center;
  }

  &.single form {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.bulk form {
    grid-template-columns: 2fr 1fr;
  }


  @include bootstrap-lg-and-below {
    display: flex;
    flex-direction: column;

    form {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .mode-toggle, input, textarea {
      margin-bottom: 20px;
      width: 100% !important;
    }
    button {
      width: 100%;
    }
  }
}
