.google-login-button {
  height: 45px;
  border-radius: 100px;
  border: solid 1px #e9eef8;
  background-color: #ffffff;
  position: relative;

  img {
    position: absolute;
    left: 15px;
  }

  span {
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    color: #7c8cac;
  }
}
