h1 {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
  color: #363636;
}

h2 {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.19;
  color: #363636;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
  color: #363636;
}

h4 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.19;
  color: #363636;
}
