#dialog {
  .modal.open {
    background-color: rgba(#363636, 0.8);
    display: block;

    .question {
      font-size: 26px;
      font-weight: bold;
      line-height: 1.19;
      color: #363636;
    }

    .modal-header {
      background-color: #e9eef8;

      .close {
        color: #7c8cac;
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}
