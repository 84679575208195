.message-display {
  position: fixed;
  top: 0;
  z-index: 100000;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;

  .message {
    font-size: 15px;
    line-height: 1.6;
    text-align: left;
    color: #363636;
    padding: 15px;

    &.success {
      background-color: #d1f5eb;
    }

    &.danger {
      background-color: #feddcb;
    }

    &.info {
      background-color: #cbf4fe;
    }

    &.warning {
      background-color: #fefacb;
    }
  }
}
