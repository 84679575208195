.list-group {
  .list-group-item {
    border: solid 1px #e9eef8;
    border-radius: 2px;
    padding-right: 12px;
    padding-left: 12px;

    a {
      color: #cbd9f5;

      &:hover {
        color: hsl(0, 0%, 60%);
      }
    }
  }
}
