.table {
  .table-row {
    display: grid;
    grid-gap: 20px;

    &.is-loading {
      svg {
        font-size: 50px;
      }
    }

    &.no-data {
      font-size: 26px;
      font-weight: bold;
      line-height: 1.19;
    }

    .table-column {
      font-family: "Barlow", sans-serif;

      align-items: center;
      display: flex;

      font-size: 15px;
      line-height: 1.2;

      &.bold {
        font-weight: bold;
        font-size: 16px;
        line-height: 1.19;
      }

      .mobile-only-icon {
        width: 12px;
      }

      &.actions-column {
        a {
          margin-right: 15px;
          font-size: 16px;
          cursor: pointer;
        }
      }

      input[type='checkbox'] {
        position: relative;
      }
    }
  }

  &.empty .table-body .table-row {
    grid-template-columns: 1fr;
  }

  .table-header {
    .table-column {
      svg {
        margin-right: 10px;
      }

      font-size: 14px;
      font-weight: bold;
      line-height: 1.21;
    }
  }

  .actions-column .mobile-toggle-button {
    display: none;
  }

  @media (max-width: 1023px) {
    .table-header {
      display: none;
    }

    .table-body {
      padding: 10px;
    }

    .table-row {
      margin-bottom: 15px;

      position: relative;

      display: flex;
      flex-wrap: wrap;
      padding: 20px 20px 15px 20px !important;

      .table-column {
        margin-bottom: 5px;
        width: 100%;
      }

      .title-column {
        margin-bottom: 10px;
        width: 100%;
      }

      .footer-column {
        width: 100%;
        display: flex;
        margin-top: 20px;
        justify-content: flex-end;
      }

      .selection-column {
        display: none;
      }

      .actions-column {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        width: auto;

        .mobile-toggle-button {
          display: block;
          font-size: 20px;

          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background: none;
          border: none;
          padding: 0;
        }

        .mobile-menu {
          display: none;

          z-index: 99;
          position: absolute;
          right: 0;
          flex-direction: column;
          padding: 0 20px;
          min-width: 250px;

          &.open {
            display: flex;
          }

          .action {
            display: flex;
            padding: 20px 0;
            font-size: 16px;
            margin-right: 0;
            align-items: center;

            svg {
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
