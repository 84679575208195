@import "../../../../assets/scss/mixins";

.above-table-container {
  @include bootstrap-lg-and-below {
    display: none !important;
  }
}

.content-container {
  @include bootstrap-lg-and-below {
    background-color: #f4f7fb;
  }
}
