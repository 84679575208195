.react-datetimerange-picker {
  .react-datetimerange-picker__wrapper {
    overflow-x: scroll;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    border-radius: 2px;
    border: solid 1px #e9eef8;
    background-color: #ffffff;
    color: #363636;

    height: 45px;
    padding-right: 55px;
    padding-left: 15px;
    width: 100%;

    .react-datetimerange-picker__inputGroup {
      font-size: 12px;
      line-height: 1;
      height: 1em !important;
      display: flex;
      align-items: center;
      color: #363636;

      input {
        font-size: 12px;
        line-height: 1;
        height: 1em !important;

        &::placeholder {
          color: #7c8cac;
        }
      }
    }

    .react-datetimerange-picker__button {
      color: #cbd9f5 !important;

      &:hover {
        color: #6f7d9a !important;
      }
    }

    .react-datetimerange-picker__inputGroup__hour,
    .react-datetimerange-picker__inputGroup__minute,
    .react-datetime-picker__inputGroup__divider:last-of-type,
    .react-datetime-picker__inputGroup__divider:nth-last-of-type(2),
    .react-datetimerange-picker__inputGroup__leadingZero {
      display: none;
    }

    .react-datetimerange-picker__range-divider {
      display: none;
    }

    .react-datetimerange-picker__clear-button {
      position: absolute;
      right: 35px;
    }

    .react-datetimerange-picker__calendar-button {
      position: absolute;
      right: 10px;
    }

    .react-datetimerange-picker__clear-button, .react-datetimerange-picker__calendar-button {
      font-size: 17px;
      color: #cbd9f5;
      padding: 0;
    }
  }
}
