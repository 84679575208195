@mixin bootstrap-sm-and-below {
  @media (max-width: 479px) {
    @content;
  }
}

@mixin bootstrap-md-and-below {
  @media (max-width: 467px) {
    @content;
  }
}

@mixin bootstrap-lg-and-below {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin bootstrap-xl-and-below {
  @media (max-width: 1199px) {
    @content;
  }
}

@include bootstrap-lg-and-below {
  .lg-and-below-hide {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .lg-and-above-hide {
    display: none !important;
  }
}
